import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { Container, Menu } from "semantic-ui-react";
import SEO from "./seo";
const Layout = ({ children, page, isMember = false }) => {
  return (
    <>
      <SEO page={page} isMember={isMember} />
      <div>
        <Menu fixed="top">
          <Container fluid>
            <Menu.Item
              name="home"
              as={Link}
              to="/"
              style={{ border: 0 }}
            >
              Home
            </Menu.Item>
			<Menu.Item
              name="directory"
              as={Link}
              to="/directory"
              style={{ border: 0 }}
            >
              Members
            </Menu.Item>
          </Container>
        </Menu>
        <Container fluid style={{ marginTop: 40 }}>
          {children}
        </Container>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
