import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ lang, page = "home" }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            pages {
              page
              title
              description
            }
          }
        }
      }
    `
  );

  const thisPage = site.siteMetadata.pages.find((p) => p.page === page);
  if (!thisPage) {
    return null;
  }
  const metaDescription = thisPage.description;
  const metaTitle = thisPage.title;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
      ]}
      script={[
        {
          src: "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js",
          "data-ad-client": "ca-pub-8961496835164159",
          async: true,
        },
      ]}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
};

SEO.propTypes = {
  page: PropTypes.string,
  lang: PropTypes.string,
};

export default SEO;
